import $ from 'jquery';
import { serial } from 'web-serial-polyfill';
import { postJsonWithCredentials } from '@universityofwarwick/serverpipe/lib/serverpipe';

const template = document.createElement('template');
template.innerHTML = `
  <style>
      @import '/assets/style.css';
  </style>
  <button class='cardTriggerButton btn btn-primary btn-lg btn-block'><i class="fa fa-id-card" aria-hidden="true"></i> Scan university card</button>

  <div class="modal fade" id="cardReaderModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span class="title"></span></h5>
      </div>
      <div class="modal-body">
        <p class="ready-to-scan">
          <i class="fa fa-id-card" aria-hidden="true"></i> Ready to scan
        </p>
        <p class="has-error hidden">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          <span class="error-message"></span>
          <span>Please try again</span>
        </p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn cancelButton">Cancel</button>
      </div>
    </div>
  </div>
</div>
`;

class CardReader extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));

    const baseUrl = this.getAttribute('baseUrl');
    const successCallbackPostUrl = this.getAttribute('successCallbackUrl');
    this.shadowRoot.querySelector('.cardTriggerButton').innerHTML = `<i class="fa fa-id-card" aria-hidden="true"></i> ${this.getAttribute('buttonText') || 'Scan university card'}`;

    let port;
    let reader;
    let readableStreamClosed;

    const $promptModal = $(this.shadowRoot.getElementById('cardReaderModal')).modal({ show: false });

    const closeReader = async () => {
      /* eslint-disable-next-line no-console */
      console.log('attempting to close device');
      try {
        await reader.cancel();
        await readableStreamClosed.catch(() => { /* Ignore the error */
        });
        await port.close();
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.info(e.message);
      }
    };

    function resetPromptModal() {
      $promptModal.find('.has-error').addClass('hidden');
      $promptModal.find('.ready-to-scan').removeClass('hidden');
      $promptModal.find('.error-message').text('');
    }

    function renderErrorMessage(message) {
      $promptModal.find('.has-error').removeClass('hidden');
      $promptModal.find('.ready-to-scan').addClass('hidden');
      $promptModal.find('.error-message').text(message);
    }

    async function getPorts() {
      return navigator.serial ? navigator.serial.getPorts() : serial.getPorts();
    }

    async function getPort() {
      return navigator.serial ? navigator.serial.requestPort({}) : serial.requestPort({});
    }

    async function getReader() {
      let rawData = '';
      let data = '';
      let ports;

      port = null;
      reader = null;
      readableStreamClosed = null;

      try {
        ports = await getPorts();
        if (ports.length > 0) {
          [port] = ports;
        } else {
          port = await getPort();
        }
        await port.open({
          baudRate: 115200,
        });

        /* eslint-disable no-undef */
        const textDecoder = new TextDecoderStream();
        readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
        reader = textDecoder.readable.getReader();

        let foundData = false;
        while (!foundData) {
          /* eslint-disable-next-line no-await-in-loop */
          const { value, done } = await reader.read();
          if (done) {
            reader.releaseLock();
            break;
          }

          rawData += value;
          if (rawData.includes('{') && rawData.includes('}')) {
            data = rawData.substring(rawData.indexOf('{'), rawData.indexOf('}') + 1);
            foundData = true;
          }
        }
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error('An error occurred decoding data from card reader', e);
        $(document).trigger('card-data-retrieved', {
          error: e.message,
        });
        return;
      }

      if (data.length > 0) {
        $(document).trigger('card-data-retrieved', JSON.parse(data));
      }
    }

    $(document).on('card-data-retrieved', async (event, jsonData) => {
      await closeReader();
      resetPromptModal();

      /*
       * Example jsonData:
       * {
       *   "error": "",
       *   "issueNumber": "02",
       *   "serialNumber": "abca122a",
       *   "startDate": "29/01/99",
       *   "universityNumber": "1574595"
       * }
       */
      if (!jsonData) {
        renderErrorMessage('Unknown error');
        await getReader();
        return;
      }

      if (jsonData.error) {
        renderErrorMessage(jsonData.error);
        await getReader();
        return;
      }

      if (baseUrl) {
        // default behaviour
        $promptModal.modal('hide');
        window.location.href = `${baseUrl}/card/${jsonData.universityNumber}/${jsonData.serialNumber}`;
      }

      if (successCallbackPostUrl) {
        /* eslint-disable-next-line no-console */
        console.log(jsonData);
        let response;
        try {
          response = await postJsonWithCredentials(successCallbackPostUrl, jsonData)
            .then(r => r.json());
        } catch (e) {
          /* eslint-disable-next-line no-console */
          console.error('failed posting card data to callback url', e);
          renderErrorMessage('Communication error with server');
          await getReader();
          return;
        }

        /* eslint-disable-next-line no-console */
        console.log(response);

        const {
          error,
          redirectURL,
        } = response || {};

        if (error) {
          renderErrorMessage(error);
          await getReader(); // allow user to retry
          return;
        }

        $promptModal.modal('hide');
        if (redirectURL) window.location.href = redirectURL;
      }
    });

    this.shadowRoot.querySelector('.cardTriggerButton').addEventListener('click', () => {
      $promptModal.modal('show');
    });

    this.shadowRoot.querySelector('.cancelButton').addEventListener('click', () => {
      $promptModal.modal('hide');
    });

    $promptModal.on('shown.bs.modal', async () => {
      resetPromptModal();
      await getReader();
    });

    $promptModal.on('hidden.bs.modal', async () => {
      resetPromptModal();
      await closeReader();
    });

    $(window).on('unload', async (e) => {
      e.preventDefault();
      await closeReader();
    });
  }
}

window.customElements.define('card-reader', CardReader);
