import $ from 'jquery';
import 'bootstrap/js/modal';

const template = document.createElement('template');
template.innerHTML = `
<style>
    @import '/assets/style.css';
</style>
<a class="btn btn-sm triggerButton"></a>

<div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span class="title"></span></h5>
      </div>
      <div class="modal-body">
        <span class="message"></span>
        <form id="confirmationForm" method="post">
            <input type="hidden" class="csrf" />
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn cancelButton"></button>
        <button type="button" class="btn confirmButton"></button>
      </div>
    </div>
  </div>
</div>`;

class ConfirmDialog extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.querySelector('.csrf').setAttribute('name', this.getAttribute('csrfName'));
    this.shadowRoot.querySelector('.csrf').setAttribute('value', this.getAttribute('csrfValue'));
    this.shadowRoot.querySelector('.triggerButton').innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.confirmButton').innerHTML = this.getAttribute('confirmButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.cancelButton').innerHTML = this.getAttribute('cancelButtonLabel') ?? 'Close';
    this.shadowRoot.querySelector('.message').textContent = this.getAttribute('message') ?? 'Are you sure?';
    this.shadowRoot.querySelector('.title').textContent = this.getAttribute('title') ?? 'Confirm';

    const confirmBtnClass = this.getAttribute('confirmBtnClass') ?? 'btn-primary';

    this.shadowRoot.querySelector('.triggerButton').classList.add(confirmBtnClass);
    this.shadowRoot.querySelector('.confirmButton').classList.add(confirmBtnClass);
    this.shadowRoot.querySelector('.cancelButton').classList.add(this.getAttribute('cancelBtnClass') ?? 'btn-secondary');

    const myModal = $(this.shadowRoot.getElementById('confirmModal')).modal({ show: false });
    const formId = this.getAttribute('formId') ?? undefined;
    let form;
    if (formId !== undefined) {
      form = document.getElementById(formId);
    } else {
      form = this.shadowRoot.getElementById('confirmationForm');
      form.action = this.getAttribute('postUrl');
    }

    this.shadowRoot.querySelector('.triggerButton').addEventListener('click', () => {
      $(myModal).modal('show');
    });

    this.shadowRoot.querySelector('.confirmButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      form.submit();
    });

    this.shadowRoot.querySelector('.cancelButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      this.dispatchEvent(new CustomEvent('closed', { bubbles: true }));
    });
  }
}

window.customElements.define('confirm-dialog', ConfirmDialog);
