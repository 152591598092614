/* eslint-disable no-console */
import { Html5QrcodeScanner, Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';

class QRCodeScanner extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    const id = this.getAttribute('targetId') ?? 'reader';
    const url = this.getAttribute('url') ?? window.location.href;

    function onScanSuccess(decodedText, decodedResult) {
      console.log(`Code matched = ${decodedText}`, decodedResult);
      window.location.replace(`${url}/${decodedText}`);
    }

    const config = {
      fps: 2,
      rememberLastUsedCamera: true,
      qrbox: { width: 250, height: 250 },
      supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
    };
    const html5QrcodeScanner = new Html5QrcodeScanner(id, config, false);
    html5QrcodeScanner.render(onScanSuccess);
  }
}

window.customElements.define('qrcode-scanner', QRCodeScanner);
