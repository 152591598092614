import $ from 'jquery';
import 'bootstrap/js/modal';

const template = document.createElement('template');
template.innerHTML = `
<style>
    @import '/assets/style.css';
</style>
<a class="redemptionButton"></a>

<div class="modal fade" id="confirmRedeemModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><span class="title"></span></h5>
      </div>
      <div class="modal-body">
        <span class="message"></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn cancelButton"></button>
        <button type="button" class="btn confirmButton"></button>
      </div>
    </div>
  </div>
</div>`;

class ConfirmRedemptionDialog extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.querySelector('.redemptionButton').innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.confirmButton').innerHTML = this.getAttribute('confirmButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.cancelButton').innerHTML = this.getAttribute('cancelButtonLabel') ?? 'Close';
    this.shadowRoot.querySelector('.title').textContent = this.getAttribute('title') ?? 'Confirm';

    const confirmBtnClass = (this.getAttribute('confirmBtnClass') ?? 'btn-primary').split(' ');
    const redemptionBtnClass = (this.getAttribute('triggerBtnClass') ?? 'btn-primary').split(' ');
    const cancelBtbClass = (this.getAttribute('cancelBtnClass') ?? 'btn-secondary').split(' ');

    $.each(confirmBtnClass, (i, c) => {
      this.shadowRoot.querySelector('.confirmButton').classList.add(c);
    });
    $.each(redemptionBtnClass, (i, c) => {
      this.shadowRoot.querySelector('.redemptionButton').classList.add(c);
    });
    $.each(cancelBtbClass, (i, c) => {
      this.shadowRoot.querySelector('.cancelButton').classList.add(c);
    });

    const myModal = $(this.shadowRoot.getElementById('confirmRedeemModal')).modal({ show: false });
    const form = document.getElementById(this.getAttribute('formId') ?? 'form');
    const message = this.shadowRoot.querySelector('.message');

    function showConfirmation() {
      message.textContent = `Are you sure you want to redeem this voucher with the value of £${document.getElementById('amount').value}?`;
      $(myModal).modal('show');
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault();
      showConfirmation();
      return false;
    });

    this.shadowRoot.querySelector('.redemptionButton').addEventListener('click', () => {
      showConfirmation();
    });

    this.shadowRoot.querySelector('.confirmButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      form.submit();
    });

    this.shadowRoot.querySelector('.cancelButton').addEventListener('click', () => {
      $(myModal).modal('hide');
      this.dispatchEvent(new CustomEvent('closed', { bubbles: true }));
    });
  }
}

window.customElements.define('confirm-redemption', ConfirmRedemptionDialog);
