import $ from 'jquery';
import 'bootstrap/js/modal';

const template = document.createElement('template');
template.innerHTML = `
<style>
    @import '/assets/style.css';
</style>
<a class="btn btn-sm triggerButton"></a>

<div class="modal fade" id="alertModal" tabindex="-1" aria-labelledby="alertModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="alertModal"><span class="title"></span></h5>
      </div>
      <div class="modal-body">
        <span class="message"></span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn confirmButton"></button>
      </div>
    </div>
  </div>
</div>`;

class ConfirmDialog extends HTMLElement {
  constructor() {
    super();

    this.attachShadow({ mode: 'open' });
    this.shadowRoot.appendChild(template.content.cloneNode(true));
    this.shadowRoot.querySelector('.triggerButton').innerHTML = this.getAttribute('triggerButtonLabel') ?? 'Submit';
    this.shadowRoot.querySelector('.confirmButton').innerHTML = this.getAttribute('confirmButtonLabel') ?? 'OK';
    this.shadowRoot.querySelector('.message').textContent = this.getAttribute('message') ?? 'Are you sure?';
    this.shadowRoot.querySelector('.title').textContent = this.getAttribute('title') ?? 'Confirm';

    const confirmBtnClass = this.getAttribute('confirmBtnClass') ?? 'btn-primary';
    this.shadowRoot.querySelector('.triggerButton').classList.add(confirmBtnClass);
    this.shadowRoot.querySelector('.confirmButton').classList.add(confirmBtnClass);

    const myModal = $(this.shadowRoot.getElementById('alertModal')).modal({ show: false });

    this.shadowRoot.querySelector('.triggerButton').addEventListener('click', () => {
      $(myModal).modal('show');
    });

    this.shadowRoot.querySelector('.confirmButton').addEventListener('click', () => {
      $(myModal).modal('hide');
    });
  }
}

window.customElements.define('modal-alert', ConfirmDialog);
